<template>
    <v-app>
        <component :is="layout">
            <v-main>
                <router-view/>
            </v-main>
            <Preloader />
        </component>
    </v-app>
</template>

<script>
    import Vue from 'vue';
    import Preloader from './components/Preloader.vue';

export default {
    name: 'App',
    components: {
        Preloader
    },
    computed: {
        layout() {
            const layoutName = this.$route.meta.layout || 'LayoutDefault';
            return () => import(`@/layouts/${layoutName}.vue`)
        },
    },
    data: () => ({
    }),
    mounted() {
    }
};
</script>
