import axios from '../../plugins/axios';

const EMPTY = {
    title: '',
    duration: '',
    quantity: '',
    price: '',
    priceForLesson: '',
    href: '',
    hrefRussian: '',
    individual: '',
    baby: '',
    enabled: true,
};

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: JSON.parse(JSON.stringify(EMPTY))
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = {a:123} }) {
            console.log(filter)
            const { data } = await axios.get('/front/prices', { params: { filter, sorting, page: 1, itemsPerPage: 10 } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/prices/${ id }`);
            commit('SET_ENTITY', data);
        },
    },

    getters: {}
}
