import Vue from 'vue';
import VueRouter from 'vue-router';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Main',
        component: () => import('../views/Main.vue'),
        // redirect: { name: 'Russian' }
    }, {
        path: '/russian',
        name: 'Russian',
        component: () => import('../views/RussianSchool.vue'),
    }, {
        path: '/math',
        name: 'Math',
        component: () => import('../views/Math.vue'),
    }, {
        path: '/chess',
        name: 'Chess',
        component: () => import('../views/Chess.vue'),
    }, {
        path: '/logopedist',
        name: 'Logopedist',
        component: () => import('../views/Logopedist.vue'),
    }, {
        path: '/conversation',
        name: 'Conversation',
        component: () => import('../views/Conversation.vue'),
    }, {
        path: '/thank-you',
        name: 'ThankYou',
        component: () => import('../views/ThankYou.vue'),
        meta: {
            reload: true,
            layout: 'LayoutEmpty'
        },
    }, {
        path: '/prices',
        name: 'Prices',
        component: () => import('../views/Prices.vue'),
        meta: {
            reload: true,
            layout: 'LayoutDefault'
        },
    }, {
        path: '/prices/russian',
        name: 'PricesRussian',
        component: () => import('../views/PricesRussian.vue'),
        meta: {
            reload: true,
            layout: 'LayoutDefault'
        },
    }, {
        path: '/prices-new',
        name: 'PricesNew',
        component: () => import('../views/PricesNew.vue'),
        meta: {
            reload: true,
            layout: 'LayoutDefault'
        },
    }
]

const router = new VueRouter({
    routes,
    base: '/',   
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (to.query) {
            if(to.query && to.query.utm_source) {
                localStorage.setItem('getskills-utm', JSON.stringify(to.query));
            }
        }
        if (to.hash) {
            return { selector: to.hash }
        } else if (savedPosition) {
            return savedPosition;
        }  else {
            return { x: 0, y: 0 }
        }
    }
});

export default router
