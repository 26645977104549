import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import './plugins/vee-validate';
import vuetify from './plugins/vuetify';
import './styles/main.scss';

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    // watch:{
    //     '$route' (to, from) {
    //         if(from.name && to.meta.reload==true) {
    //             window.location.reload()
    //         }
    //     }
    // }
}).$mount('#app')
