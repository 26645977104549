import axios from '../../plugins/axios';

const EMPTY = {
    name: '',
    subject: '',
    page: '',
    picture: '',
    description: '',
    enabled: true,
};

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: JSON.parse(JSON.stringify(EMPTY))
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = JSON.parse(JSON.stringify(EMPTY));
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null, limit = null, page = null}) {
            const { data } = await axios.get('/front/teachers', { params: { filter, sorting, limit, page } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/teachers/${ id }`);
            commit('SET_ENTITY', data);
        },
    },

    getters: {}
}