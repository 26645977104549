import axios from '../../plugins/axios.js'

export default {
    namespaced: true,

    state: {
        entity: {},
        entities: []
    },

    mutations: {
        CLEAR_ENTITY(state) {
            state.entity = {
                name: '',
                phone: '',
                email: '',
            };
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/front/feedbacks', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async save({ commit, state }) {
            const utm = JSON.parse(localStorage.getItem('getskills-utm') || '{}');
            await axios.post('/front/feedbacks', {
                ...state.entity,
                ...utm
            });
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/front/feedbacks/${ id }`);
        },
    },

    getters: {}
}