import { required, email, max, ext, size, image } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('phone', {
    validate(value, args) {
        return /^(\+7) (\d{3}) (\d{3}) (\d{2}) (\d{2})$/.test(value);
    },
    message: 'Недопустимый формат телефона'
});

extend('code', {
    validate(value, args) {
        return /^(\d{4})$/.test(value);
    },
    message: 'Недопустимый формат кода'
});

extend('social', {
    validate(value, args) {
        return /^([\w\_\-\@\.]+)$/g.test(value);
    },
    message: 'Поле может содержать только латинские буквы, цифры и знаки @_-.'
});

extend('required', {
    ...required,
    message: 'Пожалуйста, заполните поле', en: 'Fill the field please'
});

extend('max', {
    ...max,
    message: 'Колличество символов не должно превышать {length}'
});

extend('email', {
    ...email,
    message: 'Недопустимый формат email'
});

extend('ext', {
    ...ext,
    message: 'Недопустимое расширение файла'
});

extend('size', {
    ...size,
    message: (field, { size }) => {
        return `Размер файла не должен превышать ${ size / 1024 } МБ`
    }
});
