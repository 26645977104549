import Vue from 'vue'
import Vuex from 'vuex'

import feedbacks from './modules/feedback';
import teachers from './modules/teachers';
import prices from './modules/prices';
import titles from './modules/titles';
import questions from './modules/questions';
import reviews from './modules/reviews';
import videos from './modules/videos';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
      feedbacks,
      teachers,
      prices,
      titles,
      questions,
      reviews,
      videos
  }
})
